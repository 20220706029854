import { useJwt } from "react-jwt";
import { Redirect } from "react-router-dom";

interface PrivateRoutePropsType {
	children: JSX.Element;
}

export const PrivateRoute = ({ children }: PrivateRoutePropsType) => {
	// decodedToken
	const { isExpired } = useJwt(localStorage.getItem("IdToken") || "");
	// console.log("sdfsdf", isExpired, decodedToken);

	return !isExpired ? children : <Redirect to="/login" />;
};

// export const PrivateRoutes = (props) => {
//   if (isExpired) {
//     return (
//       <>
//         <Route path="login" element={<LoginForm />} />
//       </>
//     );
//   } else {
//     return (
//       <>
//         <Route path={props.path} element={props.element} />
//       </>
//     );
//   }
// };
