import { Typography, styled } from "@material-ui/core";
import { LoginForm } from "../../sections/Login/loginForm";

const StyledContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  gap: "20px",
  height: "100vh",
}));

const Login = () => {
  return (
    <div>
      <StyledContainer>
        <Typography variant="h4" style={{ width: "fit-content" }}>
          Log in
        </Typography>
        <LoginForm />
      </StyledContainer>
    </div>
  );
};

export { Login };
