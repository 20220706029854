import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const formatDateToUTC = (date: string) => {
	const parsedDate = dayjs(date).utc();
	// console.log(date, parsedDate.format("YYYY-MM-DD HH:mm:ss"));
	const formattedDate = parsedDate.format("YYYY-MM-DD HH:mm:ss");
	if (formattedDate === "Invalid Date") return "-";
	return formattedDate;
};
