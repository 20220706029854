import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Theme,
	Typography,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import { HourlyProcessChart } from "./HourlyProcessChart";
import { ChangeEvent, useEffect, useState } from "react";
import { useDashboardContext } from "../../../../../store/auth/dashboardContext";
import { CustomCircularProgress } from "../../../../../components/CircularProgress";
import {
	HourlyProcessType,
	ProcessLogsType,
	TenantProcessTypesType,
} from "../../../../../types";
import { getHours } from "../../../../../utils/getHours";
import useApi from "../../../../../hooks/useApi";
import { formatDateToUTC } from "../../../../../utils/formatDate";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			margin: theme.spacing(1),
			minWidth: 120,
			"& .MuiSelect-select": {
				padding: "8px 30px 8px 8px", // For height adjustment
			},
		},
		hourlyInsightsHeader: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "flex-start",
		},
	})
);

interface HourlyInsightsPropsType {
	tenantProcessTypes: TenantProcessTypesType;
	hourlyProcess: HourlyProcessType[];
	processLogs: ProcessLogsType[];
}

const HourlyInsights = ({
	tenantProcessTypes,
	processLogs,
	hourlyProcess,
}: HourlyInsightsPropsType) => {
	// const [hourlyProcess, setHourlyProcess] = useState<HourlyProcessType[]>([]);
	const [mappedHourlyProcess, setMappedHourlyProcess] = useState<
		HourlyProcessType[]
	>([]);
	const { tenantId, isLoading } = useDashboardContext();
	const [processType, setProcessType] = useState("all");
	const classes = useStyles();
	const hours = getHours();
	const mappedProcess: HourlyProcessType[] = hours.map((hour) => ({
		hour,
		px_tenant_id: tenantId,
		process_type: "",
		process_execution_count: 0,
		running: 0,
		completed: 0,
		failed: 0,
		retry: 0,
	}));

	const handleChangeProcessType = (
		event: ChangeEvent<{ value: unknown }>
	) => {
		setProcessType(event.target.value as string);
	};

	useEffect(() => {
		setProcessType("all");
	}, [tenantId]);

	useEffect(() => {
		if (processType !== "all") {
			const value = hourlyProcess.filter(
				(hourProcess) => hourProcess.process_type === processType
			);

			setMappedHourlyProcess(value);
		} else {
			hourlyProcess.forEach((hourProcess) => {
				const startTime = hourProcess.hour;
				const prev = mappedProcess[hours.indexOf(startTime)];
				console.log();

				mappedProcess[hours.indexOf(startTime)] = {
					hour: startTime,
					px_tenant_id: hourProcess.px_tenant_id,
					process_type: "",
					process_execution_count:
						prev.process_execution_count +
						hourProcess.process_execution_count,
					running: prev.running + hourProcess.running,
					completed: prev.completed + hourProcess.completed,
					failed: prev.failed + hourProcess.failed,
					retry: prev.retry + hourProcess.retry,
				};
			});

			setMappedHourlyProcess(mappedProcess);
		}
	}, [processType, hourlyProcess]);

	//----------------------------------------- Using logs -----------------------------------------//

	// const getStatus = (status: string, retry_attempted: number) => {
	// 	const statusMap: { [key: string]: string } = {
	// 		STARTED: "running",
	// 		ENDED: "completed",
	// 		FAILED: "failed",
	// 	};
	// 	// if (retry_attempted > 0) return "retry";
	// 	return statusMap[status];
	// };

	// useEffect(() => {
	// 	const hours = getHours();
	// 	const mappedProcesses: any = hours.map((hour) => ({
	// 		hour,
	// 		px_tenant_id: tenantId,
	// 		process_execution_count: 0,
	// 		running: 0,
	// 		completed: 0,
	// 		failed: 0,
	// 		// retry: 0,
	// 	}));

	// 	processLogs.forEach((process) => {
	// 		if (process.process_type === processType || processType === "all") {
	// 			const startTime = formatDate(process.start_datetime)
	// 				.split(" ")[1]
	// 				.split(":")[0]
	// 				.concat(":00:00");
	// 			const status = getStatus(
	// 				process.status,
	// 				process.retry_attempted
	// 			);

	// 			mappedProcesses[hours.indexOf(startTime)] = {
	// 				...mappedProcesses[hours.indexOf(startTime)],
	// 				px_tenant_id: process.px_tenant_id,
	// 				[status]:
	// 					mappedProcesses[hours.indexOf(startTime)][status] + 1,
	// 				// (1 || process.retry_attempted),
	// 			};
	// 		}
	// 	});

	// 	setHourlyProcess(mappedProcesses);
	// }, [processLogs, processType]);

	//---------------------------------------------------------------------------------------------//

	//----------------------------------------- Using api -----------------------------------------//

	// const fetchData = (response: HourlyProcessType[]) => {
	// 	setHourlyProcess(response);
	// };

	// const { loading, apiCall } = useApi(
	// 	"/getHourlyProcess",
	// 	"GET",
	// 	{
	// 		params: { date, tenantId, processType },
	// 	},
	// 	fetchData
	// );

	// useEffect(() => {
	// 	setHourlyProcess([]);

	// 	apiCall();
	// }, [date, tenantId, refreshData, processType]);

	//---------------------------------------------------------------------------------------------//

	return (
		<>
			<div className={classes.hourlyInsightsHeader}>
				<div className="flex h-center" style={{ gap: "10px" }}>
					<Typography variant="h6">Hourly Insights</Typography>
					{isLoading ? <CustomCircularProgress /> : ""}
				</div>

				<div className="flex end">
					<FormControl
						size="small"
						variant="outlined"
						className={classes.formControl}
					>
						<InputLabel>Process Type</InputLabel>
						<Select
							value={processType}
							onChange={handleChangeProcessType}
							label="Process Type"
						>
							<MenuItem value="all">All</MenuItem>
							{tenantProcessTypes[tenantId].map(
								(type: string) => (
									<MenuItem key={type} value={type}>
										{type}
									</MenuItem>
								)
							)}
						</Select>
					</FormControl>
				</div>
			</div>
			<div className="insights-chart-container">
				{/* <HourlyProcessChart data={hourlyProcess} /> */}
				<HourlyProcessChart data={mappedHourlyProcess} />
			</div>
		</>
	);
};

export { HourlyInsights };
