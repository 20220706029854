import { JsonViewer } from "../../../../../components/jsonTree";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";

interface ViewJsonTreePropsType {
	jsonData: { [key: string]: any };
	isOpen: boolean;
	handleClose: () => void;
}

const ViewJsonTree = ({
	jsonData,
	isOpen,
	handleClose,
}: ViewJsonTreePropsType) => {
	return (
		<>
			{isOpen ? (
				<Dialog onClose={handleClose} open={isOpen}>
					<DialogTitle>
						{jsonData.processId + " - " + jsonData.objectType}
					</DialogTitle>
					{/* <DialogTitle>{jsonData.id + " - " + jsonData.objectType}</DialogTitle> */}
					<DialogContent dividers>
						<JsonViewer
							jsonData={JSON.parse(jsonData.data) ?? "-"}
						/>
					</DialogContent>
					<DialogActions onClick={handleClose} color="primary">
						<Button
							variant="contained"
							color="primary"
							size="small"
							onClick={handleClose}
						>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			) : (
				""
			)}
		</>
	);
};
export { ViewJsonTree };
