import { Line } from "@ant-design/plots";
import { useEffect, useState } from "react";
import {
	HourlyProcessChartDataType,
	HourlyProcessChartPropsType,
} from "../../../../../types/ProcessMonitoring/ProcessOverview/HourlyInsights";
import { HourlyProcessType } from "../../../../../types";

const HourlyProcessChart = ({ data }: HourlyProcessChartPropsType) => {
	const [chartData, setChartData] = useState<HourlyProcessChartDataType[]>(
		[]
	);

	const fields = [
		// { key: "process_execution_count", label: "Total" },
		{ key: "completed", label: "Completed" },
		{ key: "failed", label: "Failed" },
		{ key: "running", label: "Running" },
		{ key: "retry", label: "Retry" },
	];

	useEffect(() => {
		const mappedChartData = data.map((value) => {
			const mappedValue = fields.map((field) => ({
				hour: value.hour,
				// hour: value.hour.split(" ")[1],
				value: value[field.key as keyof HourlyProcessType] as number,
				type: field.label,
			}));

			return [...mappedValue];
		});

		setChartData(mappedChartData.flat());
	}, [data]);

	const config: any = {
		data: chartData,
		xField: "hour",
		yField: "value",
		seriesField: "type",
		color: ["#30BF78", "#F4664A", "#FAAD14", "#6395fa"],
		// color: ["#5d7092", "#30BF78", "#F4664A", "#FAAD14", "#6395fa"],
		yAxis: {
			label: {
				formatter: (v: any) =>
					`${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
			},
		},
		legend: {
			position: "right",
			marker: {
				// symbol: "circle",
				radius: 3,
			},
		},
		tooltip: {
			title: (value: any) => value + ":00 Hrs",
		},
	};

	return <Line {...config} />;
};

export { HourlyProcessChart };
