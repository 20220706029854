export const getHours = () => {
	// const hours = [];
	// for (let i = 0; i < 24; i++) {
	// 	const hourString = dayjs()
	// 		.set("hour", i)
	// 		.set("minute", 0)
	// 		.set("second", 0)
	// 		.format("HH");
	// 	hours.push(hourString);
	// }
	// return hours;
	const hours = [
		"00",
		"01",
		"02",
		"03",
		"04",
		"05",
		"06",
		"07",
		"08",
		"09",
		"10",
		"11",
		"12",
		"13",
		"14",
		"15",
		"16",
		"17",
		"18",
		"19",
		"20",
		"21",
		"22",
		"23",
	];

	return hours;
};
