import {
	Card,
	CardContent,
	Theme,
	Typography,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import { ReactNode } from "react";
import { CustomCircularProgress } from "../CircularProgress";

interface ChartCardPropsType {
	title: string;
	loading?: boolean;
	children: ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		ChartCard: {
			"& .MuiCardContent-root": {
				padding: "10px",
			},
			// "& .MuiCardContent-root:last-child": {
			// 	paddingBottom: 0,
			// },
		},
	})
);

const ChartCard = ({ title, loading, children }: ChartCardPropsType) => {
	const classes = useStyles();

	return (
		<Card variant="outlined" className={classes.ChartCard}>
			<CardContent>
				<div className="flex h-center" style={{ gap: "10px" }}>
					<Typography variant="h6">{title}</Typography>
					{loading ? <CustomCircularProgress /> : ""}
				</div>
				{children}
			</CardContent>
		</Card>
	);
};

export { ChartCard };
