import { each, groupBy } from "@antv/util";
import { useEffect, useState } from "react";
import { Bar } from "@ant-design/plots";
import {
	ProcessStatusChartDataType,
	ProcessTypesChartPropsType,
} from "../../../../../types/ProcessMonitoring/ProcessOverview/ProcessStatus";
import { ProcessesCountType } from "../../../../../types";

const ProcessTypesChart = ({ chartData }: ProcessTypesChartPropsType) => {
	const [data, setData] = useState<ProcessStatusChartDataType[]>([]);

	const fields = [
		{ key: "completed", label: "Completed" },
		{ key: "failed", label: "Failed" },
		{ key: "running", label: "Running" },
		{ key: "retry", label: "Retry" },
	];

	useEffect(() => {
		const mappedChartData = chartData.map((value) => {
			const mappedValue = fields.map((field) => ({
				process_type: value.process_type,
				value: value[field.key as keyof ProcessesCountType] as number,
				type: field.label,
			}));

			return [...mappedValue];
		});

		setData(mappedChartData.flat());
	}, [chartData]);

	const annotations: any = [];
	each(groupBy(data, "process_type"), (values, k) => {
		const value = values.reduce((a: any, b: any) => a + b.value, 0);
		annotations.push({
			type: "text",
			position: [k, value],
			content: `${value}`,
			style: {
				textAlign: "center",
				// fontSize: 14,
				fill: "rgba(0,0,0,1)",
				fontWeight: "bold",
			},
			offsetX: 15,
		});
	});

	const config: any = {
		data: data,
		isStack: true,
		autoFit: true,
		appendPadding: [0, 0, 0, 5],
		limitInPlot: true,
		xField: "value",
		yField: "process_type",
		seriesField: "type",
		color: ["#30BF78", "#F4664A", "#FAAD14", "#6395fa"],
		interval: {
			size: 30, // Width of the bars
		},
		label: {
			autoHide: false,
			position: "middle",
			layout: [
				{
					type: "interval-adjust-position",
				},
				{
					type: "interval-hide-overlap",
				},
				{
					type: "adjust-color",
				},
			],
			formatter: (value: any) => {
				return value.value === 0 ? "" : value.value;
			},
		},
		annotations,
		xAxis: {
			// min: 0, // Custom minimum value for the y-axis
			max: Math.max(...data.map((item) => item.value)) + 30,
			style: {
				fill: "#686868",
			},
		},
		yAxis: {
			label: {
				style: {
					fill: "#686868",
				},
			},
		},
		legend: {
			position: "right",
			marker: {
				symbol: "circle",
			},
		},
	};

	return <Bar {...config} />;
};

export { ProcessTypesChart };
