import { LinearProgress, Typography } from "@material-ui/core";

const CustomLoader = () => {
	return (
		<>
			<Typography>Loading...</Typography>
			<LinearProgress />
		</>
	);
};

export { CustomLoader };
