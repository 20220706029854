import { createTheme } from "@material-ui/core";

export const theme = createTheme({
	overrides: {
		MuiMenuItem: {
			root: {
				fontSize: "14px",
			},
		},
	},
});
