import { useState } from "react";
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const useApi = <T,>(
	url: string,
	method: string,
	config: AxiosRequestConfig = {},
	transFn: any
) => {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const apiCall = async () => {
		setLoading(true);
		try {
			const IdToken = localStorage.getItem("IdToken");
			const header = {
				"Content-Type": "application/json",
				Authorization: "Bearer " + IdToken,
			};

			const response: AxiosResponse<T> = await axios({
				...config,
				url: BASE_URL + url,
				method,
				headers: { ...header, ...(config.headers || {}) },
			});

			setData(response.data);
			transFn(response.data);
		} catch (error) {
			setError("Error getting the data");
		} finally {
			setLoading(false);
		}
	};

	return { data, loading, error, apiCall };
};

export default useApi;
