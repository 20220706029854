import {
	AuthenticationDetails,
	CognitoUser,
	CognitoUserPool,
} from "amazon-cognito-identity-js";

const poolData = {
	UserPoolId: process.env.REACT_APP_USER_POOL_ID as string,
	ClientId: process.env.REACT_APP_CLIENT_ID as string,
};

const userPool = new CognitoUserPool(poolData);

export const authenticateUser = async (username: string, password: string) => {
	const isAuthenticated = false;
	return new Promise((resolve, reject) => {
		const user = new CognitoUser({
			Username: username,
			Pool: userPool,
		});

		const authDetails = new AuthenticationDetails({
			Username: username,
			Password: password,
		});

		user.authenticateUser(authDetails, {
			onSuccess: (result: any) => {
				// Store tokens in local storage
				localStorage.setItem(
					"IdToken",
					result.getIdToken().getJwtToken()
				);
				localStorage.setItem(
					"accessToken",
					result.getAccessToken().getJwtToken()
				);
				localStorage.setItem(
					"refreshToken",
					result.getRefreshToken().getToken()
				);
				localStorage.setItem("username", username);
				resolve(true);
			},
			onFailure: (error: Error) => {
				resolve(false);
			},
		});
		return isAuthenticated;
	});
};
