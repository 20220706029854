import { Filters } from "./Filters";
import { useDashboardContext } from "../../store/auth/dashboardContext";
import { styled } from "@material-ui/core";
import { ProcessMonitoring } from "./ProcessMonitoring";
import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { ProcessResponseType, TenantProcessTypesType } from "../../types";
import { CustomLoader } from "../../components/Loader";
import { checkIsEmpty } from "../../utils/isEmpty";

const StyledContainer = styled("div")(({ theme }) => ({
	display: "flex",
	padding: "24px",
	flexDirection: "column",
	gap: "15px",
}));

const Dashboard = () => {
	const { date, tenantId, refreshData, setIsLoading } = useDashboardContext();
	const [processSummary, setProcessSummary] = useState<ProcessResponseType>({
		config: [],
		logs: [],
		count: [],
		hourlyProcess: [],
	});
	// const { tenantProcessTypes, setTenantProcessTypes } = useDashboardContext();
	const [tenantProcessTypes, setTenantProcessTypes] =
		useState<TenantProcessTypesType>({});

	const fetchData = (response: ProcessResponseType) => {
		setProcessSummary(response);
		const mappedProcessType: TenantProcessTypesType = {};

		response.config.forEach((type) => {
			const lowercaseTenantId = type.px_tenant_id.toLowerCase();
			const existingTypes = mappedProcessType[lowercaseTenantId] || [];

			mappedProcessType[lowercaseTenantId] = [
				...existingTypes,
				type.process_type,
			];
		});

		setTenantProcessTypes(mappedProcessType);
		setIsLoading(false);
	};

	const { error, apiCall } = useApi(
		"/GetProcessSummary",
		"POST",
		{ params: { date, tenantId } },
		fetchData
	);

	useEffect(() => {
		setIsLoading(true);
		apiCall();
	}, [date, tenantId, refreshData]);

	return (
		<StyledContainer>
			{!error ? (
				checkIsEmpty(tenantProcessTypes) ? (
					<CustomLoader />
				) : (
					<>
						<Filters tenantProcessTypes={tenantProcessTypes} />
						<ProcessMonitoring
							tenantProcessTypes={tenantProcessTypes}
							processSummary={processSummary}
						/>
					</>
				)
			) : (
				// )
				"Internal Server Error"
			)}
		</StyledContainer>
	);
};

export { Dashboard };
