// import { message } from "antd";

// export const copyToClipBoard = async (text) => {
//   try {
//     navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
//       if (result.state === "granted" || result.state === "prompt") {
//         // You can use clipboard here
//         navigator.clipboard.writeText(text);
//         message.success("Copied!");
//       }
//     });
//   } catch (err) {
//     message.error("Failed to copy!");
//   }
// };

export const copyToClipBoard = (text: any) => {
  try {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    // message.success("Copied!");
  } catch (err) {
    // message.error("Failed to copy!");
  }
};
