import { ThemeProvider } from "@material-ui/core/styles";
import { AppRoutes } from "../routes";
import "./App.css";
import { theme } from "../theme";
import { DashboardProvider } from "../store/auth/dashboardContext";

const App = () => {
	return (
		<DashboardProvider>
			<ThemeProvider theme={theme}>
				<div className="flex center width-100">
					<div className="main-container width-100">
						<AppRoutes />
					</div>
				</div>
			</ThemeProvider>
		</DashboardProvider>
	);
};

export default App;
