import { useJwt } from "react-jwt";
import { Route, Switch } from "react-router-dom";
import { Login } from "../views/Login";
import { Dashboard } from "../views/Dashboard";
import { PrivateRoute } from "./private-route";

const AppRoutes = () => {
	return (
		<Switch>
			<Route exact path="/">
				<Login />
			</Route>

			<Route path="/login">
				<Login />
			</Route>

			<Route path="/dashboard">
				<PrivateRoute>
					<Dashboard />
				</PrivateRoute>
			</Route>
		</Switch>
	);
};

export { AppRoutes };
