import { Avatar, Box, Button, Popover, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

const UserProfile = () => {
	const [logout, setLogout] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [userName, setUserName] = useState("-");
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	// const navigate = useNavigate();

	useEffect(() => {
		setUserName(localStorage.getItem("username") || "");
	}, []);

	const handleLogout = () => {
		localStorage.clear();
		// localStorage.removeItem("accessToken");
		// localStorage.removeItem("refreshToken");
		// localStorage.removeItem("IdToken");
		// localStorage.removeItem("username");
		setLogout(true);
		// navigate("/login");
	};

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const content = (
		<Box sx={{ p: "10px" }}>
			<Typography variant="subtitle2">
				<b>{userName}</b>
			</Typography>
			<p></p>
			<Button
				variant="contained"
				size="small"
				color="primary"
				fullWidth
				onClick={handleLogout}
			>
				Logout
			</Button>
		</Box>
	);

	return (
		<>
			{logout && <Redirect to="/login" push />}

			<Avatar
				sizes="small"
				className="cursor-pointer"
				onClick={handleClick}
			>
				{userName.charAt(0).toUpperCase()}
			</Avatar>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
			>
				{content}
			</Popover>
		</>
	);
};

export { UserProfile };
