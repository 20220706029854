import { useState } from "react";
import { Redirect } from "react-router-dom";
import { authenticateUser } from "../../utils/authenticate";
import {
	Box,
	Button,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const LoginForm = () => {
	const [userEmail, setUserEmail] = useState("");
	const [password, setPassword] = useState("");
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [error, setIsError] = useState(false);
	// const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const handleTogglePassword = () => {
		setShowPassword(!showPassword);
	};

	const handleLogin = async () => {
		setLoading(true);
		const response = await authenticateUser(userEmail, password);
		if (response) setIsAuthenticated(true);
		setLoading(false);
		if (!isAuthenticated) setIsError(true);
		// isAuthenticated ? navigate("/dashboard") : "";
		// isAuthenticated
		//   ? navigate("/dashboard")
		//   : message.error("Invalid credentials. Please try again.");
	};

	return (
		<Box width="400px">
			{isAuthenticated && <Redirect to="/dashboard" push />}
			<form>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					id="Email"
					label="Email"
					name="email"
					autoComplete="email"
					autoFocus
					value={userEmail}
					onChange={(e) => setUserEmail(e.target.value)}
				/>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					name="password"
					label="Password"
					type={showPassword ? "text" : "password"}
					id="password"
					autoComplete="current-password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={handleTogglePassword}>
									{showPassword ? (
										<VisibilityIcon />
									) : (
										<VisibilityOffIcon />
									)}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
				<p></p>
				{error && (
					<Typography variant="body2" color="error">
						Invalid credentials. Please try again
					</Typography>
				)}
				<Button
					type="button"
					fullWidth
					variant="contained"
					color="primary"
					onClick={handleLogin}
				>
					{loading ? "Logging in..." : "Login"}
				</Button>
			</form>
		</Box>
	);
};

export { LoginForm };
