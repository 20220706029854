import { useDashboardContext } from "../../../store/auth/dashboardContext";
import {
	Card,
	FormControl,
	IconButton,
	MenuItem,
	Select,
	Theme,
	Tooltip,
	Typography,
	createStyles,
	makeStyles,
	styled,
} from "@material-ui/core";
import { ChangeEvent } from "react";
import { UserProfile } from "../../../sections/Dashboard/Filters/userProfile";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import RefreshIcon from "@material-ui/icons/Refresh";
import { TenantProcessTypesType } from "../../../types";
import { PascalCase } from "../../../utils/textCase";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			margin: theme.spacing(1),
			minWidth: 120,
		},
		selectEmpty: {
			marginTop: theme.spacing(2),
		},
		DatePicker: {
			"& .MuiSvgIcon-root": {
				fontSize: 20,
			},
			"& .MuiIconButton-root": {
				padding: 5,
			},
		},
	})
);

const StyledContainer = styled("div")(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	paddingRight: "10px",
	paddingLeft: "10px",
}));

interface FiltersPropsType {
	tenantProcessTypes: TenantProcessTypesType;
}

const Filters = ({ tenantProcessTypes }: FiltersPropsType) => {
	const {
		date,
		tenantId,
		refreshData,
		setDate,
		setTenantId,
		setRefreshData,
	} = useDashboardContext();
	const classes = useStyles();
	const localDate = dayjs();
	const todayUTC = localDate.utc().startOf("day");

	const handleSelectDate = (date: any) => {
		setDate(dayjs(date).utc().format("YYYY-MM-DD"));
	};

	const handleChangeTenantId = (event: ChangeEvent<{ value: unknown }>) => {
		setTenantId(event.target.value as string);
	};

	const handleToggleRefresh = () => {
		setRefreshData(!refreshData);
	};

	return (
		<Card variant="outlined">
			<StyledContainer>
				<Typography variant="h4">Connectors</Typography>

				<div className="flex v-center" style={{ gap: "10px" }}>
					<Tooltip title="Refresh">
						<IconButton size="small" onClick={handleToggleRefresh}>
							<RefreshIcon />
						</IconButton>
					</Tooltip>

					<FormControl
						size="small"
						variant="outlined"
						className={classes.formControl}
					>
						<Select
							value={tenantId}
							onChange={handleChangeTenantId}
						>
							{Object.keys(tenantProcessTypes).map(
								(tenant, index) => (
									<MenuItem
										key={index}
										value={tenant.toLowerCase()}
									>
										{PascalCase(tenant)}
									</MenuItem>
								)
							)}
						</Select>
					</FormControl>

					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							style={{ width: "200px" }}
							className={classes.DatePicker}
							size="small"
							disableToolbar
							variant="inline"
							inputVariant="outlined"
							format="yyyy-MM-dd"
							value={date}
							onChange={handleSelectDate}
							// disableFuture={true}
							maxDate={todayUTC}
						/>
					</MuiPickersUtilsProvider>

					<UserProfile />
				</div>
			</StyledContainer>
		</Card>
	);
};

export { Filters };
