import { useEffect, useState } from "react";
import { useDashboardContext } from "../../../../store/auth/dashboardContext";
import { CummulativeDataType } from "../../../../types/ProcessMonitoring/ProcessOverview";
import { Box, Card, CardContent, Grid } from "@material-ui/core";
import { ProcessStatusChart } from "./ProcessStatus/ProcessStatusChart";
import { HourlyInsights } from "./HourlyInsights";
import { ChartCard } from "../../../../components/Card";
import { checkIsEmpty } from "../../../../utils/isEmpty";
import {
	HourlyProcessType,
	ProcessLogsType,
	ProcessesCountType,
} from "../../../../types";
import { ProcessTypesChart } from "./ProcessStatus/ProcessTypesChart";

interface ProcessOverviewPropsType {
	tenantProcessTypes: any;
	processCount: ProcessesCountType[];
	processLogs: ProcessLogsType[];
	hourlyProcess: HourlyProcessType[];
}

const ProcessOverview = ({
	tenantProcessTypes,
	processCount,
	processLogs,
	hourlyProcess,
}: ProcessOverviewPropsType) => {
	const { isLoading } = useDashboardContext();
	const [data, setData] = useState<ProcessesCountType[]>([]);
	const [cummulativeData, setCummulativeData] = useState({
		// Process_execution_count: 0,
		Completed: 0,
		Failed: 0,
		Running: 0,
		// Retry: 0,
	});

	useEffect(() => {
		// setData([]);
		const mapData = () => {
			const summedData = processCount.reduce(
				(total: CummulativeDataType, data: ProcessesCountType) => {
					// total.Process_execution_count += data.process_execution_count;
					total.Completed += data.completed;
					total.Running += data.running;
					total.Failed += data.failed;
					// total.Retry += data.retry;
					return total;
				},
				{
					// Process_execution_count: 0,
					Completed: 0,
					Failed: 0,
					// Retry: 0,
					Running: 0,
				}
			);

			setData(processCount);
			setCummulativeData(summedData);
		};

		mapData();
	}, [processLogs]);

	return (
		<Box style={{ minHeight: "30vh" }}>
			<Grid container spacing={1} className="height-100">
				<Grid item xs={12} sm={5}>
					<ChartCard title="Status Overview" loading={isLoading}>
						<div className="insights-chart-container">
							<ProcessStatusChart data={cummulativeData} />
						</div>
					</ChartCard>
				</Grid>
				<Grid item xs={12} sm={7} className="height-100">
					<ChartCard title="Process Overview" loading={isLoading}>
						<div className="insights-chart-container">
							<ProcessTypesChart chartData={data} />
						</div>
						{/* <ProcessCountTable data={data} /> */}
					</ChartCard>
				</Grid>
				<Grid item xs={12}>
					<Card variant="outlined">
						<CardContent
							style={{
								padding: "10px",
							}}
						>
							{checkIsEmpty(tenantProcessTypes) ? (
								"Loading ..."
							) : (
								<HourlyInsights
									tenantProcessTypes={tenantProcessTypes}
									processLogs={processLogs}
									hourlyProcess={hourlyProcess}
								/>
							)}
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
};

export { ProcessOverview };
