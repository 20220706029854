import { Pie } from "@ant-design/plots";
import { ProcessStatusChartPropsType } from "../../../../../types/ProcessMonitoring/ProcessOverview";

const ProcessStatusChart = ({ data }: ProcessStatusChartPropsType) => {
	const chartData = Object.entries(data).map(([type, value]) =>
		type !== "Process_execution_count"
			? {
					type,
					value,
			  }
			: {}
	);

	const config: any = {
		appendPadding: 10,
		data: chartData,
		color: ["#30BF78", "#F4664A", "#FAAD14", "#6395fa"],
		angleField: "value",
		colorField: "type",
		radius: 0.75,
		innerRadius: 0.64,
		meta: {
			value: {
				formatter: (v: number) => `${v}`,
			},
		},
		label: {
			type: "spider",
			labelHeight: 28,
			content: "{name}\n{value}",
		},
		statistic: {
			title: {
				style: {
					fontSize: 14,
				},
			},
			content: {
				offsetY: 4,
				style: {
					fontSize: "26px",
				},
			},
		},

		interactions: [
			{
				type: "element-selected",
			},
			{
				type: "element-active",
			},
			{
				type: "pie-statistic-active",
			},
		],
	};

	return <Pie {...config} />;
};

export { ProcessStatusChart };
